.search-select-component {
    position: relative;
}

.search-select-component img{
    position: absolute;
    width: 30px;
    right: 0;
    z-index: 3;
}
.search-select-component select {
    width: 189px;
    height: 40px;
    background-image: url("img.png");
    position: relative;
    z-index: 2;
    padding: 0 15px;
    background-size: 25px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    border: 1px solid #888888;
    border-radius: 4px;
    font-style: italic;
    -webkit-appearance: none;
    font-size: 14px;
    -moz-appearance: none;
    outline: none;
}