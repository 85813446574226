.input-icon-container {
    display: flex;
    align-items: center;
    gap: 30px;
}

.input-icon {
    margin-right: 10px;
    padding-right: 20px;
}

.br-select{
    max-width: none !important;
}

