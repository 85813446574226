.editor{
    width: 100%;
    height: var(--editorHeight);
    padding: 20px;
    margin-top: 0.5rem;
    border: 1px solid #E0E0E0;
    overflow: auto !important;
    white-space: normal; 
    word-break: break-word;
}

/* .table table td, .table table th { 
    min-width: 1em; 
    border: 1px solid #000; 
    padding: 3px 5px; 
    vertical-align: top; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
    position: relative; 
} */
table {
    border-collapse: separate !important;
    border-spacing: 0;
}
.table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
}
  
.table td, .table th {
    border: 2px solid #ced4da;
    box-sizing: border-box;
    min-width: 1em;
    padding: 3px 5px;
    position: relative;
    vertical-align: top;
}
  
.table td > *, .table th > * {
    margin-bottom: 0;
}
  
.table th {
    background-color: #f1f3f5;
    font-weight: bold;
    text-align: left;
}
  
.table .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
}
  
.table .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    pointer-events: none;
    top: 0;
    width: 4px;
}

.table p {
    margin: 0;
}

.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}

.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}
  
.image > * + *{
    margin-top: 0.75em;
}

.image img {
    height: auto;
    max-width: 1017px;
}

[class*='postimage-']:has(+ .icon-resize){
    outline: 3px solid #68cef8;
}


.resize-trigger{
    display: flex;
    align-items: center;
}

.image p{
    outline: 0px solid transparent;
}

@font-face {
    font-family: 'Calibri';
    src: url('../../assets/fontes/calibri/Calibri.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../../assets/fontes/calibri/CALIBRIB.TTF') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../../assets/fontes/calibri/CALIBRII.TTF') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Calibri';
    src: url('../../assets/fontes/calibri/CALIBRIZ.TTF') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Carlito';
    src: url('../../assets/fontes/carlito/Carlito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Carlito';
    src: url('../../assets/fontes/carlito/Carlito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Carlito';
    src: url('../../assets/fontes/carlito/Carlito-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Carlito';
    src: url('../../assets/fontes/carlito/Carlito-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}



@font-face {
    font-family: 'Arial';
    src: url('../../assets/fontes/arial/ArialMT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arial';
    src: url('../../assets/fontes/arial/Arial-BoldMT.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Arial';
    src: url('../../assets/fontes/arial/Arial-ItalicMT.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Arial';
    src: url('../../assets/fontes/arial/Arial-BoldItalicMT.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}