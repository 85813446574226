.ant-modal-close-x {
    color: #1351B4 !important;
}

.ant-modal-close-x svg {
    filter: drop-shadow(1px 0 0 #1351B4)
    drop-shadow(-1px 0 0 #1351B4)
    drop-shadow(0 1px 0 #1351B4)
    drop-shadow(0 -1px 0 #1351B4)
}

.ant-modal-title {
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Rawline", serif !important;
}